import EditIcon from '@eg/elements/Icons/EditIcon';
import Button from '@eg/elements/Button';
import { useAppSelector } from 'hooks/hooks';
import getTxt from 'helpers/getTxt';
import { DataLayerIds, Options } from 'helpers/enums';
import { getChoiceSelectionsText, PriceDetails } from './PriceDetails';
import { IDefProps } from 'interfaces';
import { AemWindow } from 'helpers/interfaces';
import config from 'config/config';
import useTracking from 'dataLayerAPI/useTracking';
import './price.scss';
import { ElementsToScrollEnum, handleScrollToElement } from 'helpers/helpers';
import { Keys, storage } from 'helpers';
import { selectUserData } from 'stores/userDataStore';
import { PRICE_BASE_CLASS } from './Price.const';

const agentQueryKey = 'adh_oenr';

const getOTRHref = (): string => {
  const { params } = window as AemWindow;
  const appId = params && Object.keys(params).filter((k) => k.includes(config.aemAppId))[0];
  if (appId) {
    const otrPathname = params[appId][config.otrRedirectKey];
    const redirectUrl = window.location.origin + otrPathname;
    return redirectUrl.endsWith('.html') ? redirectUrl : `${redirectUrl}.html`;
  }
  return config.otrUrlRedirect;
};

const getAgentQuery = (): string => {
  const search = window.location.search;
  if (!search) return '';

  const queries = search.split('&');
  const agentQuery = queries.find((query: string) => query.includes(agentQueryKey));
  if (!agentQuery) return '';

  return `&${agentQuery.replace('&', '').replace('?', '')}`;
};

const getSearchQuery = (businessId: string | null, teeth: Options, dentures: Options) => {
  const tariff1 = teeth !== 'KS' ? teeth : 'None';
  const tariff2 = dentures !== 'KS' ? dentures : 'None';
  const id = businessId ? `businessId=${businessId}&` : '';
  const agentNumber = getAgentQuery();
  return `?${id}tariff1=${tariff1}&tariff2=${tariff2}${agentNumber}`;
};

const Price: React.FC<IDefProps> = ({ parentId }) => {
  const {
    birthday,
    businessId,
    multiplieChoiceOptions: { teeth, dentures },
  } = useAppSelector(selectUserData);
  const { trackClickEvent } = useTracking();

  const handleClick = (id: string) => {
    const _businessId = businessId;
    storage.remove(Keys.businessId);
    trackClickEvent(id);
    const url = getOTRHref();
    const searchQuery = getSearchQuery(_businessId, teeth, dentures);
    window.location.href = url + searchQuery + `#/${config.otrInitialRoute}`;
  };

  const handleScroll = (id: string) => {
    trackClickEvent(id);
    handleScrollToElement(ElementsToScrollEnum.BIRTHDAY_FORM);
  };

  const renderBirthday = () => {
    if (birthday === null) {
      return (
        <div
          id={DataLayerIds.IZS_Link_GeburtsdatumEingeben}
          onClick={() => handleScroll(DataLayerIds.IZS_Link_GeburtsdatumEingeben)}
          className={`${PRICE_BASE_CLASS}__bday ${PRICE_BASE_CLASS}__bday--empty`}>
          {getTxt('price.bdayGive')}
        </div>
      );
    }

    const date = new Date(birthday).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return (
      <div className={`${PRICE_BASE_CLASS}__bday`}>
        {getTxt('bday.yourDate')} {date}
        <EditIcon
          className={`${PRICE_BASE_CLASS}__icon-to-top`}
          onClick={() => handleScroll(DataLayerIds.IZS_Icon_GeburtsdatumAendern)}
          id={DataLayerIds.IZS_Icon_GeburtsdatumAendern}
        />
      </div>
    );
  };

  const identifier = `${parentId}_price`;

  return (
    <div className={PRICE_BASE_CLASS} id={`${identifier}`}>
      <div className={`${PRICE_BASE_CLASS}__text`} id={`${identifier}_container-text`}>
        <p className={`${PRICE_BASE_CLASS}__text-combination`}>
          {getChoiceSelectionsText(teeth, dentures)}
        </p>
        <div className={`${PRICE_BASE_CLASS}__value`}>
          <PriceDetails />
        </div>
      </div>
      <div className={`${PRICE_BASE_CLASS}__bday`}>{renderBirthday()}</div>
      <div className={`${PRICE_BASE_CLASS}__margin-lower`} id={`${identifier}_container-button`}>
        <Button
          id={DataLayerIds.IZS_Button_JetztOnlineAbschließen}
          variant='primary'
          size='large'
          onClick={() => handleClick(DataLayerIds.IZS_Button_JetztOnlineAbschließen)}>
          {getTxt('price.button')}
        </Button>
      </div>
    </div>
  );
};
export default Price;
